import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { DevelopmentIcon } from 'components/ui';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      maxWidth: '80vw',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '250px',
    },
  },
  images: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
});

const FeaturedItem = (props) => {
  const { data, classes } = props;
  return (
    <Box
      component={Grid}
      container
      alignItems="center"
      justifyContent={{ xs: 'flex-end', md: 'center' }}
      position="relative"
    >
      <Box position="absolute" className={classes.wrapper}>
        <DevelopmentIcon item={data} border={true} expanded={true} />
      </Box>
      {data.development.document.data.listing_images.map((item, key) => {
        return (
          <Grid item xs={12} lg={6} key={key} className={classes.images}>
            <img
              className="w-100"
              src={`${item.image.url}&ar=2:1.5&fit=crop`}
              alt={item.image.alt ? item.image.alt : ''}
            />
          </Grid>
        );
      })}
    </Box>
  );
};

FeaturedItem.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const StandardItem = (props) => {
  const { data, portfolio, classes } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Box position="absolute" className={classes.wrapper}>
        <DevelopmentIcon
          item={data}
          border={true}
          expanded={portfolio ? false : true}
          portfolio={portfolio ? portfolio : false}
        />
      </Box>
      {data.development.document.data.listing_images.map((item, key) => {
        if (portfolio && key > 0) return null;
        return (
          <Box key={key} className={classes.images} pl={[0, 0, 10, 10]}>
            <img
              className="w-100"
              src={`${item.image.url}&ar=2:1.4&fit=crop`}
              alt=""
            />
          </Box>
        );
      })}
    </Box>
  );
};

StandardItem.propTypes = {
  data: PropTypes.object.isRequired,
  portfolio: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

const ListDevelopments = (props) => {
  const { data, items, classes } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={6}>
          {items.map((item, key) => {
            if (
              data.portfolio_or_active_developments !==
              item?.development?.document?.data?.archive_status
            )
              return null;
            return key === 0 ? (
              <Grid item xs={12} key={key}>
                <FeaturedItem data={item} classes={classes} />
              </Grid>
            ) : (
              <Grid item xs={12} lg={6} key={key}>
                <StandardItem data={item} classes={classes} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

ListDevelopments.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const ListPortfolio = (props) => {
  const { data, items, classes } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={6}>
          {items.map((item, key) => {
            if (
              data.portfolio_or_active_developments !==
              item.development.document.data.archive_status
            )
              return null;
            return (
              <Grid item xs={12} lg={6} key={key}>
                <StandardItem data={item} classes={classes} portfolio={true} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

ListPortfolio.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

function AllDevelopments(props) {
  const { data, items, classes } = props;

  return data.portfolio_or_active_developments ? (
    <ListDevelopments data={data} items={items} classes={classes} />
  ) : (
    <ListPortfolio data={data} items={items} classes={classes} />
  );
}

AllDevelopments.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AllDevelopments);
